import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {  CellValueChangedEvent, ColDef, DomLayoutType } from 'ag-grid-community';
import { ServiceNumberDatabase, ServiceNumberDatabaseItem } from 'src/app/utils/service-number-database';
import { GenericHeaderEditorComponent } from '../generic-header-editor/generic-header-editor.component';

@Component({
  selector: 'app-service-number-database-editor',
  templateUrl: './service-number-database-editor.component.html',
  styleUrls: ['./service-number-database-editor.component.css']
})
export class ServiceNumberDatabaseEditorComponent implements OnInit {

  @Input() crmtFile?: ServiceNumberDatabase
  @ViewChild(GenericHeaderEditorComponent) headerEditor?: GenericHeaderEditorComponent
  @Input() disableAppGenericHeaderEditorFields:boolean = true

  public rowData!: ServiceNumberDatabaseItem[]

  private edited = false
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;

  public columnDefs: ColDef[] = [
    { headerName: "Number", field: 'dialingNumber', resizable: false },
    { headerName: "Service Name", field: 'serviceName', resizable: false }
  ];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    
    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {
    
      this.rowData = this.crmtFile.entries
    }
  }

  public onCellValueChanged(e: CellValueChangedEvent<any, any>) {
    this.edited = true
  }

  public getData() {

    const headerEdited = this.headerEditor?.edited

    return {
      done: this.edited || headerEdited,
      fileType: 'service-number-database',
      fileData: this.crmtFile
    }
  }
}


