import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { switchMap } from 'rxjs';
import { DeviceInfoService } from 'src/app/services/device-info.service';
import { OrientationConfig, OrientationPackageData, OrientationToolService } from 'src/app/services/orientation-tool.service';
import * as THREE from 'three';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

@Component({
  selector: 'app-orientation-tool',
  templateUrl: './orientation-tool.component.html',
  styleUrls: ['./orientation-tool.component.css']
})
export class OrientationToolComponent implements OnInit {

  @Input() device: string | null = null;
  @Output() orientation = new EventEmitter<OrientationPackageData>();

  @ViewChild('antennaOffset') antennaOffset?: ElementRef;
  @ViewChild('trainClass') trainClass?: ElementRef;
  public isLoading = true;
  // 3D model stuff
  private camera!: THREE.PerspectiveCamera
  private scene!: THREE.Scene
  private renderer!: THREE.WebGLRenderer
  private radioModel!: THREE.Group<THREE.Object3DEventMap>
  private angleDelta = 0

  // Orientation stuff
  public orientationIndex = 0
  private orientationConfigs: OrientationConfig[] = []
  private orientationQuaternions: THREE.Quaternion[] = []
  private deviceType = "TEST"

  // Mouse shoogle stuff
  private mouseX = 0
  private mouseY = 0;

  constructor(
    private deviceInfoService: DeviceInfoService,
    private orientationService: OrientationToolService) { }

  public onMouseLeave(e: any) {

    // Reset the mouse position on exit
    this.mouseX = 0
    this.mouseY = 0
  }

  public onMouseMove(e: any) {
    // Find the center of the canvas
    const rect = e.target.getBoundingClientRect();
    const xCenter = (rect.left + rect.right) / 2;
    const yCenter = (rect.top + rect.bottom) / 2;

    // Find mouse relative to center
    const scaleFactor = 0.1; // Reduced scale factor for slower movement
    this.mouseX = scaleFactor * (e.clientX - xCenter);
    this.mouseY = scaleFactor * (e.clientY - yCenter);
}

  ngOnInit(): void {
    this.orientation.emit()
    if(!this.device) return

    this.initialiseCanvas()

    this.deviceInfoService.Inventory(this.device).pipe(
      switchMap(inventory => {

        this.deviceType = inventory.deviceType
        return this.orientationService.getOrientations(inventory.deviceType)
      })
    ).subscribe(orientations => {

      // Separate the config structure
      this.orientationConfigs = orientations.map(item => item.config)

      // Separate the quaternion structure
      this.orientationQuaternions = orientations.map(item => new THREE.Quaternion().fromArray(item.quaternion))

      // Generate the model based on the device type
      switch(this.deviceType) {

        case "SVR400": this.createSVR400(); break;

        case "SVR511": this.createSVR511(); break;

        // Other radio models go here
        // ...

        default: this.createSVR400(); break;
      }
    })
  }

  private initialiseCanvas() {

    const canvas = document.getElementById('canvas-box') as HTMLCanvasElement

    if (!canvas) return;

    const canvasSizes = {
      width: window.innerWidth / 4,
      height: window.innerWidth / 4,
     };

    canvas.width = canvasSizes.width
    canvas.height = canvasSizes.height
  }

  private createSVR400() {

			var scene = new THREE.Scene();

      const canvas = document.getElementById('canvas-box') as HTMLCanvasElement

      if (!canvas) return;


      const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true
      });

      const camera = new THREE.PerspectiveCamera(
        50,
        canvas.width / canvas.height,
        1,
        1000);

      renderer.setClearColor( 0x000028, 1);
      renderer.setSize(canvas.width, canvas.height);
      renderer.outputColorSpace = THREE.SRGBColorSpace;
      renderer.setPixelRatio(window.devicePixelRatio);

      // turn on the physically correct lighting model
      // renderer.physicallyCorrectLights = true;
      renderer.shadowMap.enabled = true
      renderer.shadowMap.type = THREE.PCFSoftShadowMap

      // Directional light ambient colour and intensity
      var directionalLight = new THREE.DirectionalLight(0xF0F0F0, 1); 
                  
      // Cast shadows at a low resolution over baked textures
      directionalLight.castShadow = true;
      directionalLight.shadow.mapSize.width = 512
      directionalLight.shadow.mapSize.height = 512
      
      // Don't cast shadows beyond projection near far clipping points
      directionalLight.shadow.camera.near = 1
      directionalLight.shadow.camera.far = 1000

      const light = new THREE.AmbientLight( 0x404040 ); // soft white light
      scene.add( light );

      // Add directional light to camera so camera forward is 
      // where the directional light unit vector aims
      camera.add(directionalLight);

      // Load in the environment map for the model
      const path = 'assets/models/SVR400/pbr/experimental/';
      const format = '.png';
      const urls = [
          path + 'px' + format, path + 'nx' + format,
          path + 'py' + format, path + 'ny' + format,
          path + 'pz' + format, path + 'nz' + format
      ];
      const reflectionCube = new THREE.CubeTextureLoader().load(urls);
      // Apparently important for MeshStandardMaterial ( docs don't even say why, cheers -_- )
      reflectionCube.colorSpace = THREE.SRGBColorSpace;

      scene.environment = reflectionCube;
      
      // Model is big
      camera.position.z = 550;     
      camera.updateProjectionMatrix();

      scene.add(camera);

      // Enable this to get a taste of what the object is reflecting
      // scene.background = reflectionCube;
      let model: any
      // Load in obj version of mesh once the environment map has loaded
      const objLoader = new OBJLoader()
      objLoader.load(
          'assets/models/SVR400/LOD0.obj',
          (object) => {

              model = object
              // Create the PBR materials here...
              const M1_blinn1SG = this.GenerateMeshStandardMaterial('assets/models/SVR400/pbr/M1_blinn1SG', reflectionCube, 0.2, 0.3, 0.6, 'white')
              const M1_blinn2SG = this.GenerateMeshStandardMaterial('assets/models/SVR400/pbr/M1_blinn2SG', reflectionCube, 1.0, 0.0, 0.0, 0xB0B0B0)
              const M1_blinn3SG = this.GenerateMeshStandardMaterial('assets/models/SVR400/pbr/M1_blinn3SG', reflectionCube, 0.4, 0.7, 0.6, 'white')
              const M1_blinn4SG = this.GenerateMeshStandardMaterial('assets/models/SVR400/pbr/M1_blinn4SG', reflectionCube,  0.0, 0.3, 0.6, 'white')
              const M1_blinn5SG = this.GenerateMeshStandardMaterial('assets/models/SVR400/pbr/M1_blinn5SG', reflectionCube,  0.6, 0.3, 0.4, 'white')

              // Create bounding box around model to get min/max vertex to calc center offset
              var boundingbox = new THREE.BoxHelper(object, 0xff0000);
              boundingbox.update();

              object.traverse(thing  => {

                  const mesh = thing as THREE.Mesh
                  // Offset the bounding box position per mesh to center it
                  if (mesh.isMesh) {
                      // Add 2nd set of uvs for the AO map
                      // (mesh.geometry.attributes as any).uv2 = (mesh.geometry.attributes as any).uv;
                      if(boundingbox.geometry.boundingSphere != null) {
                        mesh.translateX(-boundingbox.geometry.boundingSphere.center.x);
                        mesh.translateY(-boundingbox.geometry.boundingSphere.center.y);
                        mesh.translateZ(-boundingbox.geometry.boundingSphere.center.z);
                      }
                  }
                  // Debuggging, print out preexisting OBJ materials
                  // console.log(mesh.material);

                  // Assign updated 'Standard' materials
                  if (mesh.material) {

                      const material = mesh.material as any

                      // Outside casing
                      if (material.name == "CabRadio:blinn1SG") {
                          mesh.material = M1_blinn1SG;
                      }
                      // Internal cooling fins
                      else if (material.name == "CabRadio:blinn2SG") {
                          mesh.material = M1_blinn2SG;
                      }
                      // Fan
                      else if (material.name == "CabRadio:blinn3SG") {
                          mesh.material = M1_blinn3SG;
                      }
                      // Rando button
                      else if (material.name == "CabRadio:blinn4SG") {
                          mesh.material = M1_blinn4SG;
                      }
                      // All knobs buttons etc...
                      else if (material.name == "CabRadio:blinn5SG") {
                          mesh.material = M1_blinn5SG;
                      }
                  }
                  
              })
              
              this.radioModel = object
              scene.add(object)

              this.setOrientation(0)
              this.animate()
          },
          (xhr) => {
              console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
          },
          (error) => {
              console.log('An error happened:' + error)
          }
      );
      
      this.scene = scene
      this.camera = camera
      this.renderer = renderer
      this.isLoading = false;
  }

  private createSVR511() {

    var scene = new THREE.Scene();

    const canvas = document.getElementById('canvas-box') as HTMLCanvasElement

    if (!canvas) return;

    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true
    });

    const camera = new THREE.PerspectiveCamera(50, canvas.width / canvas.height, 1, 1000);
    renderer.setClearColor(0x000028, 1);
    renderer.setSize(canvas.width, canvas.height);
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    // Directional light with increased intensity
    var directionalLight = new THREE.DirectionalLight(0xffffff, 2); // Increased intensity
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.width = 512;
    directionalLight.shadow.mapSize.height = 512;
    directionalLight.shadow.camera.near = 1;
    directionalLight.shadow.camera.far = 1000;

    camera.add(directionalLight);
    camera.position.z = 8;
    camera.updateProjectionMatrix();
    scene.add(camera);

    // Adding ambient light
    var ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // Ambient light with lower intensity
    scene.add(ambientLight);

    // Adding a point light for additional illumination
    var pointLight = new THREE.PointLight(0xffffff, 1, 100);
    pointLight.position.set(10, 10, 10);
    scene.add(pointLight);

    const mtlLoader = new MTLLoader();
    mtlLoader.load(
      'assets/models/SVR511/svr511.mtl',
      (materials) => {
        materials.preload();

        const objLoader = new OBJLoader();
        objLoader.setMaterials(materials);
        objLoader.load(
          'assets/models/SVR511/svr511.obj',
          (object) => {
            this.radioModel = object;
            scene.add(object);

            this.setOrientation(0);
            this.animate();
          },
          (xhr) => {
            console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
          },
          (error) => {
            console.log('An error happened: ' + error);
          }
        );
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      (error) => {
        console.log('An error happened: ' + error);
      }
    );

    this.scene = scene
    this.camera = camera
    this.renderer = renderer
    this.isLoading = false
  }

  // Animation function called on each frame
  private animate() {

    if(this.orientationQuaternions.length) {

      // Move a delta amount towards the destination position each frame
      const desiredQuaternion = this.orientationQuaternions[this.orientationIndex]
      this.radioModel.quaternion.rotateTowards(desiredQuaternion, this.angleDelta)
    }

    // Move the camera a little bit towards the mouse pointer each frame
    const responsiveness = 0.05
    this.camera.position.x += ( this.mouseX - this.camera.position.x ) * responsiveness;
    this.camera.position.y += ( - this.mouseY - this.camera.position.y ) * responsiveness;
    this.camera.lookAt( this.scene.position );

    // Draw the scene
    this.renderer.render(this.scene, this.camera );
    requestAnimationFrame(this.animate.bind(this));
  };

  public changeOrientation(direction: boolean) {

    const delta = direction ? 1 : this.orientationQuaternions.length - 1
    const index = (this.orientationIndex + delta) % this.orientationQuaternions.length

    this.setOrientation(index)
  }

  private setOrientation(index: number) {

    this.orientationIndex = index

    // Calculate the angular distance to get from
    // our current position to the desires position
    const desired = this.orientationQuaternions[index]
    const current = this.radioModel.quaternion
    const angle = current.angleTo(desired)

    // Divide it by the number of frames we want the
    // transition to take. This makes each transition
    // the same duration.
    this.angleDelta = angle / 50

    // Output the orientation to the parent control
    this.inputChangeHandler()
  }

  public inputChangeHandler() {

    // Read the input controls
    const offset = parseInt(this.antennaOffset?.nativeElement.value || 0)
    const trainClass = this.trainClass?.nativeElement.value || ""
    const buildNo = 0

    // Get the orientation
    const orientation = this.orientationConfigs[this.orientationIndex]

    // Output the package data
    const packageData: OrientationPackageData = {
      device: this.device || "",
      roll: orientation.x,
      pitch: orientation.y,
      yaw: orientation.z,
      offset,
      buildNo,
      trainClass
    }

    this.orientation.emit(packageData)
  }

  private GenerateMeshStandardMaterial(base_dir: string, env: any, rough: any, metal: any, env_pwr: any, col: any) {
    var tex_loader = new THREE.TextureLoader();
    return new THREE.MeshStandardMaterial({ 
        // color: is set to 'white / 0xffffff' by default
        side: THREE.DoubleSide,
        fog: false,
        color: col,
        map: tex_loader.load(base_dir + '_BaseColor.png'),
        bumpMap: tex_loader.load(base_dir + '_Height.png'),
        normalMap: tex_loader.load(base_dir + '_Normal.png'),
        roughnessMap: tex_loader.load(base_dir + '_Roughness.png'),
        metalnessMap: tex_loader.load(base_dir + '_Metallic.png'),
        aoMap: tex_loader.load(base_dir + '_AO.png'),
        aoMapIntensity: 4.3,
        // Use default scene environment map
        envMap: env,
        envMapIntensity: env_pwr,
        roughness: rough,
        metalness: metal
    });
  }

  // This method is no longer used but I've left it in because it
  // demonstrates how I generate the quaternion values that are
  // stored in the backend.
  // 
  // If new 3D models are added for other radio types then you
  // will probably need to do something similar.
  private generateQuaternions() {

    // Set up a 90 degree rotation in each individual axis
    const x = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0).normalize(), Math.PI / 2)
    const y = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,1,0).normalize(), Math.PI / 2)
    const z = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,0,1).normalize(), Math.PI / 2)

    // Then combine them to rotate the model so that the positions match the
    // images used in the original Orientation Tool
    const pos1 = x.clone()
    const pos2 = pos1.clone().multiply(z).multiply(z)
    const pos3 = pos2.clone().multiply(y).multiply(y).multiply(y)
    const pos4 = pos2.clone().multiply(y)
    const pos5 = pos1.clone().multiply(y).multiply(y).multiply(y)
    const pos6 = pos1.clone().multiply(y)
    const pos7 = pos6.clone().multiply(z).multiply(y).multiply(y).multiply(y)
    const pos8 = pos4.clone().multiply(z).multiply(z).multiply(z)
    const pos9 = pos3.clone().multiply(z)
    const pos10 = pos3.clone().multiply(z).multiply(z).multiply(z)
    const pos11 = pos4.clone().multiply(z)
    const pos12 = pos9.clone().multiply(y)
    const pos13 = pos8.clone().multiply(y)
    const pos14 = pos7.clone().multiply(y).multiply(y)
    const pos15 = pos4.clone().multiply(x).multiply(y).multiply(y)
    const pos16 = pos3.clone().multiply(x)

    // Put the values into the array
    this.orientationQuaternions = [
      pos1,
      pos2,
      pos3,
      pos4,
      pos5,
      pos6,
      pos7,
      pos8,
      pos9,
      pos10,
      pos11,
      pos12,
      pos13,
      pos14,
      pos15,
      pos16
    ]
  }
}
