import { CrmtDecoder } from "./crmt-decoder";
import { CrmtFile } from "./crmt-file";

export interface FanUsageLogItem {

    fanEvent: number;
    loggedDate: string;
    loggedTime: string;
    duration: number;
}

export class FanUsageLog extends CrmtFile {

    public entries: FanUsageLogItem[] = [];

    constructor() {
        super()
    }

    public static fromRawData(buffer: ArrayBuffer): FanUsageLog {
        
        const decoder = new CrmtDecoder(buffer);

        // Create the new object
        const thingy = new FanUsageLog();

        // Extract the actual entries
        const entryCount = decoder.getUint32();
        const firstEntryIndex = decoder.getUint32();

        for(let e = 0; e < entryCount; e++) {

            const fanEvent = decoder.getUint16();
            const loggedDate = decoder.getCrDate()
            const loggedTime = decoder.getCrTime();
            const duration = decoder.getUint32();

            thingy.addEntry(
                fanEvent,
                loggedDate,
                loggedTime,
                duration)
        }

        return thingy;
    }

    public addEntry(
        fanEvent: number,
        loggedDate: string,
        loggedTime: string,
        duration: number): void {

        const entry: FanUsageLogItem = {
            fanEvent,
            loggedDate,
            loggedTime,
            duration
        }

        this.entries.push(entry)
    }
}
