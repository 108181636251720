export const environment = {
    production: false,
    applicationName: require('../../../crmt-gui/package.json').name,
    applicationVersion: require('../../../crmt-gui/package.json').version,
    
    oktaConfig: {
      issuer: 'https://dev-19106765.okta.com/oauth2/default',
      clientId: '0oaks4hjwoh0FGirW5d7',
      redirectUri: window.location.origin + '/login/callback'
    },

    backendConfig: {
      url: "https://crmt-pune-api.64836216846.com/"
    },
    configs:{
      attachedSystemsParametersSystemValues : ["Not Used","Intercom","Public Address","ERTMS/ETCS","Train-borne Recorder","Not Used","Not Used","Not Used"],
      trainCrewParametersCrewMemberValues : ["Driver 2","Driver 3","Driver 4","Driver 5","Chief Conductor","Chief Caterer","Chief Security","Departure Procedure Lead Driver","Departure Procedure Chief Conductor"]
    }
  };
