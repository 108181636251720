import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { CellValueChangedEvent, ColDef, GridApi, GridReadyEvent, RowEditingStartedEvent, RowEditingStoppedEvent, RowSelectedEvent, GridOptions, DomLayoutType } from 'ag-grid-community';
import { SmsDatabase, SmsDatabaseItem } from 'src/app/utils/sms-database';
import { CrmtNumericEditor } from '../../cell-editors/crmt-numeric-editor.component';
import { PhoneNumberEditor } from '../../cell-editors/phone-number-editor.component';
import { StringEditor } from '../../cell-editors/string-editor.component';
import { GenericHeaderEditorComponent } from '../generic-header-editor/generic-header-editor.component';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { EditStateService } from 'src/app/services/edit-state.service';
import { ModalService } from '@siemens/ix-angular';
import { DuplicateValueCheckServiceService } from 'src/app/services/duplicate-value-check-service.service';

@Component({
  selector: 'app-sms-database-editor',
  templateUrl: './sms-database-editor.component.html',
  styleUrls: ['./sms-database-editor.component.css']
})
export class SmsDatabaseEditorComponent implements OnInit {

  @Input() crmtFile?: SmsDatabase
  @ViewChild(GenericHeaderEditorComponent) headerEditor?: GenericHeaderEditorComponent

  public rowData!: SmsDatabaseItem[]
  public disableAddRow = true
  public disableDeleteRow = true
  public enableEditing = false
  public disableEditing = true

  private gridApi!: GridApi;
  private edited = false
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;
  public errorMessage: string = "";
  public checkDuplicateIndex:  boolean = false;
  @ViewChild('customModalForErrorMessage', { read: TemplateRef })
  customModalForErrorMessage!: TemplateRef<any>;

  displayedColumns: string[] = ['index', 'number', 'message'];

  public gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus: true,
  };

  public columnDefs: ColDef[] = [
    {
      checkboxSelection: true,
      sortable: false,
      resizable: false,
      filter: false,
      suppressMenu: true,
      width: 48
    },
    { 
      headerName: "Index number", 
      field: 'indexNumber', 
      resizable: false,
      editable: () => this.enableEditing,
      cellEditor: CrmtNumericEditor, 
      cellEditorParams: {
        min: 1,
        max: 100
      }
    },
    { 
      headerName: "Destination number", 
      field: 'destinationNumber', 
      editable: () => this.enableEditing,
      resizable: true, 
      sortable: true, 
      filter: true,
      cellEditor: PhoneNumberEditor, 
      cellEditorParams: {
        phoneNumberType: 'sms',
        minLength: 0,
        maxLength: 20
      }      
    },
    { 
      headerName: "Text message", 
      field: 'textMessage', 
      resizable: true, 
      sortable: true, 
      filter: true ,
      editable: () => this.enableEditing,
      cellEditor: StringEditor, 
      cellEditorParams: {
        minLength: 0,
        maxLength: 160
      }      
    }
  ];

  constructor(
    private readonly permissions: PermissionsService, private editStateService: EditStateService,
    private readonly modalService: ModalService,
    private duplicateValueCheckService: DuplicateValueCheckServiceService) {}

  ngOnInit() { 

    this.permissions.getUserLevel().subscribe(level => {

      this.enableEditing = level >= UserLevel.STANDARD
      this.disableEditing = !this.enableEditing
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    
    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {

      this.rowData = this.crmtFile.entries
      this.initAddRow()
    }
    this.duplicateValueCheckService.setGridData(this.rowData);
    this.checkDuplicateIndex = this.duplicateValueCheckService.getUniqueData();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.duplicateValueCheckService.setGridData(this.rowData);
    this.checkDuplicateIndex = this.duplicateValueCheckService.getUniqueData();
  }

  onRowSelected(e: RowSelectedEvent): void {
    
    const selectedRows = this.gridApi.getSelectedRows()
    this.disableDeleteRow = selectedRows.length == 0
  }

  public onCellValueChanged(e: any) {
    this.edited = true
    this.editStateService.setEditedState(true);
  }

  public getData() {

    const headerEdited = this.headerEditor?.edited

    return {
      done: this.edited || headerEdited,
      fileType: 'sms-database',
      fileData: this.crmtFile
    }
  }

  public exitEditMode() {
    this.edited = false
  }

  onRowEditingStarted(event: RowEditingStartedEvent) {
    this.disableAddRow = true
  }

  onRowEditingStopped (event: RowEditingStoppedEvent) {
    this.duplicateValueCheckService.setGridData(this.rowData);
    this.checkDuplicateIndex = this.duplicateValueCheckService.getUniqueData();
    this.initAddRow()
  }
  
  public addRow() {
    // Add a new row to the source data and the grid
    const newRow = { indexNumber: 1, destinationNumber: '', textMessage: ''}

    // Check if all existing rows have a valid destination number and text message, and are not null
    const allRowsValid = this.rowData.every(row =>
      row.destinationNumber && row.destinationNumber.trim() !== '' &&
      row.textMessage && row.textMessage.trim() !== '' &&
      row.destinationNumber !== null && row.textMessage !== null
    );

    if (allRowsValid  && this.checkDuplicateIndex) {
      this.rowData.push(newRow)
      const result = this.gridApi.applyTransaction({
        add: [newRow],
      });

      // Allow the user to edit the row
      const rowIndex = result?.add[0].rowIndex

      if (rowIndex || rowIndex == 0) {
        this.gridApi.startEditingCell({
          rowIndex,
          colKey: 'indexNumber'
        })
      }
    } else {
      if(!allRowsValid && !this.checkDuplicateIndex){
        this.errorMessage="Invalid Data Entry: Duplicate Index Numbers are not permitted. All fields in the row are mandatory. Please make the correction to proceed. "
      }
      else if(!allRowsValid){
        this.errorMessage = "Invalid Data Entry: All fields in the row are mandatory. Please make the correction to proceed. "
      }
      else if(!this.checkDuplicateIndex){
        this.errorMessage = "Invalid Data Entry: Duplicate Index Numbers are not permitted. Please make the correction to proceed."
    }
    this.openModalForErrorMessage()
    }
  }  

  public deleteRow() {

    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex
    const id = selection[0].data.index;

    if(index || index == 0) {
      this.rowData.splice(index, 1)
      this.gridApi.applyTransaction({remove: [selection[0].data]})
      this.edited = true
      this.disableAddRow = false
    }
    this.duplicateValueCheckService.setGridData(this.rowData);
    this.checkDuplicateIndex = this.duplicateValueCheckService.getUniqueData();
  }

  private initAddRow() {
    const maxRows = 100
    this.disableAddRow = this.rowData.length >= maxRows
  }

  public editRow() {
    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex
    this.gridApi!.setFocusedCell(index as number, "indexNumber");
    this.gridApi!.startEditingCell({
      rowIndex: index as number,
      colKey: "indexNumber",
    });
    this.duplicateValueCheckService.setGridData(this.rowData);
    this.checkDuplicateIndex = this.duplicateValueCheckService.getUniqueData();
  }

  async openModalForErrorMessage() {
    const instance = await this.modalService.open({
      content: this.customModalForErrorMessage
    })
    instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});
  }
}
