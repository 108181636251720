import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ToastService } from '@siemens/ix-angular';
import { SmsDatabase } from 'src/app/utils/sms-database';
import { StandardPhonebook } from 'src/app/utils/standard-phonebook';
import { SmsDatabaseEditorComponent } from '../sms-database-editor/sms-database-editor.component';
import { PhonebookEditorComponent } from '../phonebook-editor/phonebook-editor.component';
import { ExtendedPhonebook } from 'src/app/utils/extended-phonebook';
import { UusDatabase } from 'src/app/utils/uus-database';
import { UusDatabaseEditorComponent } from '../uus-database-editor/uus-database-editor.component';
import { CabRadioParametersEditorComponent } from '../cab-radio-parameters-editor/cab-radio-parameters-editor.component';
import { CabRadioParameters } from 'src/app/utils/cab-radio-parameters';
import { AttachedSystemsParametersEditorComponent } from '../attached-systems-parameters-editor/attached-systems-parameters-editor.component';
import { AttachedSystemsParameters } from 'src/app/utils/attached-systems-parameters';
import { TrainCrewParametersEditorComponent } from '../train-crew-parameters-editor/train-crew-parameters-editor.component';
import { TrainCrewParameters } from 'src/app/utils/train-crew-parameters';
import { PeriodTimerParametersEditorComponent } from '../period-timer-parameters-editor/period-timer-parameters-editor.component';
import { PeriodTimerParameters } from 'src/app/utils/period-timers-parameters';
import { ServiceNumberDatabaseEditorComponent } from '../service-number-database-editor/service-number-database-editor.component';
import { ServiceNumberDatabase } from 'src/app/utils/service-number-database';
import { ResponseActionDatabaseEditorComponent } from '../response-action-database-editor/response-action-database-editor.component';
import { ResponseActionDatabase } from 'src/app/utils/response-action-database';
import { FaultLog } from 'src/app/utils/fault-log';
import { FanUsageLog } from 'src/app/utils/fan-usage-log';
import { FaultLogViewerComponent } from '../fault-log-viewer/fault-log-viewer.component';
import { FanUsageLogViewerComponent } from '../fan-usage-log-viewer/fan-usage-log-viewer.component';

@Component({
  selector: 'app-generic-file-editor',
  templateUrl: './generic-file-editor.component.html',
  styleUrls: ['./generic-file-editor.component.css']
})
export class GenericFileEditorComponent implements OnInit, OnChanges {

  @Input() file: any = {}

  @Input() hideHeaderEditor: boolean = true 

  @ViewChild(PhonebookEditorComponent) phonebookEditor?: PhonebookEditorComponent
  @ViewChild(SmsDatabaseEditorComponent) smsDatabaseEditor?: SmsDatabaseEditorComponent
  @ViewChild(UusDatabaseEditorComponent) uusDatabaseEditor?: UusDatabaseEditorComponent
  @ViewChild(CabRadioParametersEditorComponent) cabRadioParametersEditor?: CabRadioParametersEditorComponent
  @ViewChild(AttachedSystemsParametersEditorComponent) attachedSystemsParametersEditor?: AttachedSystemsParametersEditorComponent
  @ViewChild(TrainCrewParametersEditorComponent) trainCrewParametersEditor?: TrainCrewParametersEditorComponent
  @ViewChild(PeriodTimerParametersEditorComponent) periodTimerParametersEditor?: PeriodTimerParametersEditorComponent
  @ViewChild(ServiceNumberDatabaseEditorComponent) serviceNumberDatabaseEditor?: ServiceNumberDatabaseEditorComponent
  @ViewChild(ResponseActionDatabaseEditorComponent) responseActionDatabaseEditor?: ResponseActionDatabaseEditorComponent
  @ViewChild(FaultLogViewerComponent) faultLogViewer?: FaultLogViewerComponent
  @ViewChild(FanUsageLogViewerComponent) fanUsageLogViewer?: FanUsageLogViewerComponent
  
  public fileType = 'standard-phonebook'
  public standardPhonebook?: StandardPhonebook
  public extendedPhonebook?: ExtendedPhonebook
  public smsDatabase?: SmsDatabase
  public uusDatabase?: UusDatabase
  public cabRadioParameters?: CabRadioParameters
  public attachedSystemsParameters?: AttachedSystemsParameters
  public trainCrewParameters?: TrainCrewParameters
  public periodTimerParameters?: PeriodTimerParameters
  public serviceNumberDatabase?: ServiceNumberDatabase
  public responseActionDatabase?: ResponseActionDatabase
  public faultLog?: FaultLog
  public fanUsageLog?: FanUsageLog

  constructor(
    private readonly toastService: ToastService) {}

  ngOnInit(): void {
    this.fileType = this.idFileType(this.file)
  }

  ngOnChanges(changes: SimpleChanges) {

    this.fileType = this.idFileType(this.file)
  }
  
  public getData() {

    let data

    switch(this.fileType) {

      case "standard-phonebook": data = this.phonebookEditor?.getData(); break;
      case "extended-phonebook": data = this.phonebookEditor?.getData(); break;
      case "sms-database": data = this.smsDatabaseEditor?.getData(); break;
      case "uus-database": data = this.uusDatabaseEditor?.getData(); break;
      case "cab-radio-parameters": data = this.cabRadioParametersEditor?.getData(); break;
      case "attached-systems-parameters": data = this.attachedSystemsParametersEditor?.getData(); break;
      case "train-crew-parameters": data = this.trainCrewParametersEditor?.getData(); break;
      case "period-timer-parameters": data = this.periodTimerParametersEditor?.getData(); break;
      case "service-number-database": data = this.serviceNumberDatabaseEditor?.getData(); break;
      case "response-action-database": data = this.responseActionDatabaseEditor?.getData(); break;

      // These files are not editable but I've included them here for 'completeness'
      case "fault-log": data = this.faultLogViewer?.getData(); break;
      case "fan-usage-log": data = this.fanUsageLogViewer?.getData(); break;

      default: data = { done: false }
    }

    return data
  }

  private idFileType(fileObject: any): string {

    let fileType: string

    if (fileObject instanceof StandardPhonebook) {
      fileType = "standard-phonebook"
    }
    else if (fileObject instanceof ExtendedPhonebook) {
      fileType = "extended-phonebook"
    }
    else if (fileObject instanceof SmsDatabase) {
      fileType = "sms-database"
    }
    else if (fileObject instanceof UusDatabase) {
      fileType = "uus-database"
    }
    else if (fileObject instanceof CabRadioParameters) {
      fileType = "cab-radio-parameters"
    }
    else if (fileObject instanceof AttachedSystemsParameters) {
      fileType = "attached-systems-parameters"
    }
    else if (fileObject instanceof TrainCrewParameters) {
      fileType = "train-crew-parameters"
    }
    else if (fileObject instanceof PeriodTimerParameters) {
      fileType = "period-timer-parameters"
    }
    else if (fileObject instanceof ServiceNumberDatabase) {
      fileType = "service-number-database"
    }
    else if (fileObject instanceof ResponseActionDatabase) {
      fileType = "response-action-database"
    }
    else if (fileObject instanceof FaultLog) {

      fileType = "fault-log"
    }
    else if (fileObject instanceof FanUsageLog) {
      fileType = "fan-usage-log"
    }
    else {
      throw "Unrecognised file type"
    }

    return fileType
  }

  public exitEditMode () {
    switch(this.fileType) {
      case "standard-phonebook": this.phonebookEditor?.exitEditMode(); break;
      case "extended-phonebook": this.phonebookEditor?.exitEditMode(); break;
      case "sms-database": this.smsDatabaseEditor?.exitEditMode(); break;
      case "uus-database": this.uusDatabaseEditor?.exitEditMode(); break;
      case "cab-radio-parameters": this.cabRadioParametersEditor?.exitEditMode(); break;
      case "attached-systems-parameters": this.attachedSystemsParametersEditor?.exitEditMode(); break;
      case "train-crew-parameters": this.trainCrewParametersEditor?.exitEditMode(); break;
      case "period-timer-parameters": this.periodTimerParametersEditor?.exitEditMode(); break;
      default: ""
    }
  }

  async showToastMessage() {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: 'Deployment job submitted',
      type: "success",
      autoCloseDelay: 10000
    });
  }
}
