import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, ToastService } from '@siemens/ix-angular';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-device-registration-page',
  templateUrl: './device-registration-page.component.html',
  styleUrls: ['./device-registration-page.component.css']
})
export class DeviceRegistrationPageComponent implements OnInit {

  public disableStandard = true
  public invalidData = true
  public isSerialNumberValid = false
  private deviceType!: string
  private serialNumber!: string
  public errorMessage: string = "";
  @ViewChild('customModalForErrorMessage', { read: TemplateRef })
  customModalForErrorMessage!: TemplateRef<any>;

  constructor(
    private readonly permissions: PermissionsService,
    private readonly _apiService: ApiService,
    private readonly toastService: ToastService,
    private readonly modalService: ModalService) {}

  ngOnInit() { 

    this.permissions.getUserLevel().subscribe(level => {

      this.disableStandard = level < UserLevel.STANDARD
    })
  }
  
  public deviceTypeChanged(event: string) {

    this.deviceType = event
    this.validate()
  }

  public serialNumberChanged(event: any) {

    this.serialNumber = event.target.value
    this.validate()

    const pattern = /^[a-zA-Z0-9]{8}$/;
    this.isSerialNumberValid = !pattern.test(this.serialNumber);
  }

  private validate() {
    this.invalidData = 
      this.deviceType == undefined ||
      this.serialNumber == undefined ||
      this.serialNumber?.length == 0
  }

  public registerDevice() {
    this._apiService.registerDevice(this.deviceType, this.serialNumber).subscribe({
        next: () => {
          this.showToastMessage()
        },
        error: (e) => {
          this.errorMessage = e.message
          this.openModalForErrorMessage()
        }
      })
  }

  async showToastMessage() {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: `Radio ${this.serialNumber} pending registration.
       Please power on the device within the next 15 minutes to complete registration`,
      type: "success",
      autoCloseDelay: 10000
    });
  }

  async openModalForErrorMessage() {
    const instance = await this.modalService.open({
      content: this.customModalForErrorMessage
    })
    instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});
  }

}
