import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { CellValueChangedEvent, ColDef, GridApi, GridReadyEvent, RowEditingStartedEvent, RowEditingStoppedEvent, RowSelectedEvent, GridOptions, DomLayoutType } from 'ag-grid-community';
import { StandardPhonebook, StandardPhonebookItem } from 'src/app/utils/standard-phonebook';
import { CrmtNumericEditor } from '../../cell-editors/crmt-numeric-editor.component';
import { PhoneNumberEditor } from '../../cell-editors/phone-number-editor.component';
import { StringEditor } from '../../cell-editors/string-editor.component';
import { ExtendedPhonebook } from 'src/app/utils/extended-phonebook';
import { GenericHeaderEditorComponent } from '../generic-header-editor/generic-header-editor.component';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { EditStateService } from 'src/app/services/edit-state.service';
import { ModalService } from '@siemens/ix-angular';
import { DuplicateValueCheckServiceService } from 'src/app/services/duplicate-value-check-service.service';

@Component({
  selector: 'app-phonebook-editor',
  templateUrl: './phonebook-editor.component.html',
  styleUrls: ['./phonebook-editor.component.css']
})
export class PhonebookEditorComponent implements OnInit {

  @Input() crmtFile?: StandardPhonebook | ExtendedPhonebook
  @Input() fileType = 'standard-phonebook'
  @ViewChild(GenericHeaderEditorComponent) headerEditor?: GenericHeaderEditorComponent

  public disableAddRow = true
  public disableDeleteRow = true
  public rowData: StandardPhonebookItem[] = []
  public enableEditing = false
  public disableEditing = true
  @Input() hideAppGenericHeaderEditor:boolean = true 

  private edited = false
  private gridApi!: GridApi;
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;
  public errorMessage: string = "";
  public checkDuplicateIndex: boolean = false;
  @ViewChild('customModalForErrorMessage', { read: TemplateRef })
  customModalForErrorMessage!: TemplateRef<any>;

  public gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus: true,
  };
  public columnDefs: ColDef[] = [
    {
      checkboxSelection: true,
      sortable: false,
      resizable: false,
      filter: false,
      suppressMenu: true,
      width: 48
    },
    {
      headerName: "Index number", 
      field: 'index', 
      resizable: false, 
      sortable: true,
      editable: () => this.enableEditing,
      cellEditor: CrmtNumericEditor, 
      cellEditorParams: {
        min: 1,
        max: 254
      }
    },
    { headerName: "Dialling number", 
      field: 'number', 
      editable: () => this.enableEditing,
      resizable: true, 
      sortable: true, 
      filter: true,
      cellEditor: PhoneNumberEditor, 
      cellEditorParams: {
        minLength: 0,
        maxLength: 20
      }      
    },
    { 
      headerName: "Description", 
      field: 'description', 
      resizable: true, 
      sortable: true, 
      filter: true,
      editable: () => this.enableEditing,
      cellEditor: StringEditor, 
      cellEditorParams: {
        minLength: 0,
        maxLength: 20
      }      
    }
  ];

  constructor(
    private readonly permissions: PermissionsService, private editStateService: EditStateService, 
    private readonly modalService: ModalService,
    private duplicateValueCheckService: DuplicateValueCheckServiceService) {}

  ngOnInit() { 

    this.permissions.getUserLevel().subscribe(level => {

      this.enableEditing = level >= UserLevel.STANDARD
      this.disableEditing = !this.enableEditing
    })
  }


  ngOnChanges(changes: SimpleChanges) {
    this.crmtFile = changes["crmtFile"].currentValue
    if(this.crmtFile) {
      this.updateCellEditorParams();
      this.rowData = this.crmtFile.entries || []
      this.initAddRow()
    }
    this.duplicateValueCheckService.setGridData(this.rowData);
    this.checkDuplicateIndex = this.duplicateValueCheckService.getUniqueData();
  }

  private updateCellEditorParams(): void {
    this.columnDefs.forEach(colDef => {
      if (colDef.field === 'index') {
        colDef.cellEditorParams = {
          min: 1,
          max: this.fileType === 'standard-phonebook' ? 254 : 1200
        };
      }
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.duplicateValueCheckService.setGridData(this.rowData);
    this.checkDuplicateIndex = this.duplicateValueCheckService.getUniqueData();
  }

  onRowSelected(e: RowSelectedEvent): void {
    
    const selectedRows = this.gridApi.getSelectedRows()

    this.disableDeleteRow = selectedRows.length == 0
  }

  onRowEditingStarted(event: RowEditingStartedEvent) {
    this.disableAddRow = true
  }

  onRowEditingStopped (event: RowEditingStoppedEvent) {
    this.duplicateValueCheckService.setGridData(this.rowData);
    this.checkDuplicateIndex = this.duplicateValueCheckService.getUniqueData();
    this.initAddRow()
  }

  public onCellValueChanged(e: any) {
    this.edited = true
    this.editStateService.setEditedState(true);    
  }


  public getData() {

    const headerEdited = this.headerEditor?.edited

    return {
      done: this.edited || headerEdited,
      fileType: this.fileType,
      fileData: this.crmtFile
    }
  }

  public exitEditMode() {
    this.edited = false
  }

  public addRow() {
     
    // Create a new row with default values
    const newRow = { index: 1, number: '', description: ''};

    // Check if all existing rows have a valid number and description, and are not null
    const allRowsValid = this.rowData.every(row =>
      row.number && row.number.trim() !== '' &&
      row.description && row.description.trim() !== '' &&
      row.number !== null && row.description !== null
    );

    if (allRowsValid && this.checkDuplicateIndex) {
      // Add the new row to the source data and the grid
      this.rowData.push(newRow);
      const result = this.gridApi.applyTransaction({
        add: [newRow],
      });

      // Allow the user to edit the row
      const rowIndex = result?.add[0].rowIndex;

      if (rowIndex || rowIndex === 0) {
        this.gridApi.startEditingCell({
          rowIndex,
          colKey: 'index'
        });
      }
    } else {
      if(!allRowsValid && !this.checkDuplicateIndex){
        this.errorMessage="Invalid Data Entry: Duplicate Index Numbers are not permitted. All fields in the row are mandatory. Please make the correction to proceed."
      }
      else if(!allRowsValid){
        this.errorMessage = "Invalid Data Entry: All fields in the row are mandatory. Please make the correction to proceed."
      }
      else if(!this.checkDuplicateIndex){
        this.errorMessage = "Invalid Data Entry: Duplicate Index Numbers are not permitted. Please make the correction to proceed."
    }
    this.openModalForErrorMessage();
  }
}

  public deleteRow() {

    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex
    const id = selection[0].data.index;
    
    if(index || index == 0) {
      this.rowData.splice(index, 1)
      this.gridApi.applyTransaction({remove: [selection[0].data]})
      this.edited = true
      this.disableAddRow = false
    }
    this.duplicateValueCheckService.setGridData(this.rowData);
    this.checkDuplicateIndex = this.duplicateValueCheckService.getUniqueData();
  }

  public editRow() {
    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex
    this.gridApi!.setFocusedCell(index as number, "index");
    this.gridApi!.startEditingCell({
      rowIndex: index as number,
      colKey: "index",
    });
    this.duplicateValueCheckService.setGridData(this.rowData);
    this.checkDuplicateIndex = this.duplicateValueCheckService.getUniqueData();
  }
  
  private initAddRow() {

    // Max for standard phonebook
    let maxRows = 254

    if(this.crmtFile instanceof ExtendedPhonebook) {

      // Max for extended phonebook
      maxRows = 1200
    }

    this.disableAddRow = this.rowData.length >= maxRows
  }

  async openModalForErrorMessage() {
    const instance = await this.modalService.open({
      content: this.customModalForErrorMessage
    })
    instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});
  }
}
