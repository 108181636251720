<app-file-type-selector 
    (fileType)="fileTypeChanged($event)">
</app-file-type-selector>

<app-device-type-selector [fileTypeSelected]="fileTypeSelected"
    (deviceType)="deviceTypeChanged($event)">
</app-device-type-selector>

<div *ngIf="enableEditOption;">
    <ix-content-header
        variant="Secondary"
        headerTitle="Select File"
        headerSubtitle="Select the file to deploy">
    </ix-content-header>

    <ix-upload
        i18nUploadFile="Select"
        loadingText="Uploading..."
        [accept]="validExtensions"
        [multiple]="expectedFileConfig.fileCount == 1 ? false : true"
        (filesChanged)="filesChanged($event)">
    </ix-upload>    

    <ix-key-value-list>
        <ix-key-value
        label="Selected file"
        labelPosition="left"
        value="{{selectedFileText}}">
        </ix-key-value>
    </ix-key-value-list>
</div>
<ng-template #customModalForErrorMessage>
    <ix-modal>
        <ix-modal-header> {{ errorMessage }} </ix-modal-header>
    </ix-modal>
</ng-template>