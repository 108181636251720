import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridApi, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import * as moment from 'moment'

export interface JobListItem {
  createdAt: string;
  status: string;
  jobId: string;
  type: string;
  action: string;
  user: string;
}

const ELEMENT_DATA: JobListItem[] = []

@Component({
  selector: 'app-crmt-job-list',
  templateUrl: './crmt-job-list.component.html',
  styleUrls: ['./crmt-job-list.component.css']
})
export class CrmtJobListComponent implements OnInit {

  @Input() refreshed: boolean = false;
  @Output() selectedJob = new EventEmitter<string>();
  @Output() selectedForCancellation = new EventEmitter<string[]>();
  @Output() refreshCompleted = new EventEmitter<void>();

  displayedColumns: string[] = ['position', 'name', 'type', 'action'];
  dataSource = ELEMENT_DATA;
  private gridApi!: GridApi;
  public isLoading = false;
  private paginationPageSize = 15;
  private nextToken: string = "";
  private currentPage = 1;
  public columnDefs: ColDef[] = [
    { headerName: "Creation time", field: 'createdAt', resizable: false, checkboxSelection: true },
    { headerName: "Status", field: 'status', resizable: true },
    { headerName: "File type", field: 'type', resizable: true, sortable: false, filter: true },
    { headerName: "Action", field: 'action', resizable: true },
    { headerName: "User", field: 'user', resizable: true },
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
  };

  // enables pagination in the grid
  public pagination = true;

  // Set to `true` so that the number of rows to load per page is automatically adjusted by the grid
  public paginationAutoPageSize = true;

  // Data that gets displayed in the grid
  public rowData$: JobListItem[] = []

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  constructor(
    private _apiService: ApiService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['refreshed'] && this.refreshed) {
        this.refreshData(); // Call refreshData if refresh is true
      }
    }
    
  ngOnInit(): void {}

  onGridReady(params: GridReadyEvent) {
    this.gridApi= params.api;
    this.updateLoadingOverlay()
    this.getJobList();
  }
  private updateLoadingOverlay(): void {
    if (this.gridApi) {
      if (this.isLoading) {
        this.gridApi.showLoadingOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    }
  }

  refreshData(){
    // Empty row data on refresh
    this.rowData$ = []
    this.nextToken = ""
    this.getJobList();
  }

  getJobList(){
    this.isLoading= true;
    this.gridApi.showLoadingOverlay();
    this._apiService.getJobList(this.nextToken, this.paginationPageSize).subscribe((data: any) => {
      const jobs = data.message.jobDetails
      this.nextToken = data.message.nextToken
      const response = jobs.map((row: any) => {
        //To show details of a job
        return this.getDetailsForRow(row);            
      })
      const combinedArray = [...this.rowData$, ...response];
      this.rowData$ = combinedArray
      this.isLoading= false;
      this.gridApi.hideOverlay();
      this.refreshCompleted.emit();
    })
  }

  private getDetailsForRow(row: any){
    const newItem: JobListItem = {
      createdAt: moment.utc(row.job.createdAt).local().format('YYYY-MM-DD HH:mm:ss'),
      status: row.job.status.replace('CANCELED', 'CANCELLED'),
      jobId: row.job.jobId,
      type: row.tags.type,
      action: row.tags.action,
      user: row.tags.user
    }

    return newItem
  }

  onPaginationChanged(event: any) {
    const api = event.api; 
    const newPage = api.paginationGetCurrentPage() + 1; 
    if (newPage > this.currentPage ){
      // Fetch data when user goes to new page
      this.currentPage = newPage; 
      this.getJobList();
    }else if(newPage < this.currentPage && event.newData){
      // Reset the page when Refresh button is clicked
      this.currentPage = newPage; 
    }
    
  }

  // Output the job ID
  onCellClicked(e: CellClickedEvent): void {

    const selectedJob = e.data.jobId
    this.selectedJob.emit(selectedJob)

  }

  onRowSelected(e: RowSelectedEvent): void {

    const selectedRows = this.agGrid.api.getSelectedRows()

    const selectedCancelJob = selectedRows.map(item => {
      return item.jobId;
    })

    this.selectedForCancellation.emit(selectedCancelJob)
  }

}