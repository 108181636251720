import { CrmtDecoder } from "./crmt-decoder";
import { CrmtEncoder } from "./crmt-encoder";
import { CrmtFile, CrmtHeader, ICrmtFile } from "./crmt-file";

export interface ExtendedPhonebookItem {
    number: string;
    index: number;
    description: string;
}

// Note - the extended phonebook seems to be exactly the same as the
// standard phonebook with the exception of the maximum number of entries
export class ExtendedPhonebook extends CrmtFile implements ICrmtFile {

    public entries: ExtendedPhonebookItem[] = [];

    constructor(public header: CrmtHeader) {
        super()
    }

    public static fromRawData(buffer: ArrayBuffer): ExtendedPhonebook {
        
        const decoder = new CrmtDecoder(buffer);

        // Extract the common header values
        const header = decoder.getHeader()

        // Create the new object
        const thingy = new ExtendedPhonebook(header);

        // Extract the actual entries
        const entryCount = decoder.getUint32();

        for(let e = 0; e < entryCount; e++) {

            const index = decoder.getUint16();
            const number = decoder.getLengthEncodedAscii();
            const description = decoder.getLengthEncodedUtf();
    
            thingy.addEntry(index, number, description);
        }

        return thingy;
    }

    public toRawData(): ArrayBuffer {

        const encoder = new CrmtEncoder(100 * 1024)

        // // Create the header
        encoder.setHeader(this.header)

        // Write the actual entries
        const entryCount = this.entries.length
        encoder.setUint32(entryCount);

        const entries: ExtendedPhonebookItem[] = []

        for(let e = 0; e < entryCount; e++) {

            const entry = this.entries[e]
            encoder.setUint16(entry.index);
            encoder.setLengthEncodedAscii(entry.number);
            encoder.setLengthEncodedUtf(entry.description);
        }

        return encoder.getFileData()
    }

    public addEntry(
        index: number,
        number: string,
        description: string): void {

        const entry: ExtendedPhonebookItem = {
            index,
            number,
            description
        }    

        this.entries.push(entry)
    }
}
