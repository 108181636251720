<div class="wrapper">
    <ag-grid-angular
    style="width: 100%; height: 100%;"
    class="ag-theme-alpine-dark ag-theme-ix"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [treeData]="true"
    [animateRows]="true"
    [gridOptions]="gridOptions"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [getDataPath]="getDataPath"
    [getRowId]="getRowId"
    [autoGroupColumnDef]="autoGroupColumnDef"
    (rowDragEnter)="onRowDragEnter($event)"
    (gridReady)="onGridReady($event)"
    (rowGroupOpened)="onRowGroupOpened($event)"
    (paginationChanged)="onPaginationChanged($event)"
  ></ag-grid-angular>
</div>  
<ng-template #customModal let-modal>
  <div class="box-modal">
      <ix-modal-header class="mod-header">Select organization or subgroup to move / copy your device</ix-modal-header>
      <ix-modal-content>
          <app-fleet-group-selector-modal
              [filePath]="filePaths"
              (selection)="selection($event)"
          ></app-fleet-group-selector-modal>
      </ix-modal-content>
      <ix-modal-footer>
          <ix-button
              outline
              class="dismiss-modal"
              (click)="modal.dismiss('dismiss')">
              Cancel
          </ix-button>
          <ix-button class="close-modal confirm-btn" 
              (click)="modal.close('okay')" 
              [disabled]="confirmDisabled">
              Confirm
          </ix-button>
      </ix-modal-footer>
  </div>
</ng-template>