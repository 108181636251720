<ix-content-header
    variant="Secondary"
    headerTitle="Response Action Database"
    headerSubtitle="Data is read only">
</ix-content-header>

<app-generic-header-editor
    [header]="crmtFile?.header"
    [disableInputField]=disableAppGenericHeaderEditorFields>
</app-generic-header-editor>

<ag-grid-angular
    class="ag-theme-alpine-dark ag-theme-ix"
    [columnDefs]="columnDefs"
    [rowData]="rowData"
    [rowSelection]="'single'"
    [animateRows]="true"
    [domLayout]="domLayout"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize"
    (cellValueChanged)="onCellValueChanged($event)">
</ag-grid-angular>
