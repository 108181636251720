<div class="wrapper">
  <ag-grid-angular
      class="ag-theme-alpine-dark ag-theme-ix"
      style="height: 100%; width: 100%;"
      [rowData]="filePath"
      [gridOptions]="gridOptions"
      [rowSelection]="'single'"
      [getDataPath]="getDataPath"
      [getRowId]="getRowId"
      [autoGroupColumnDef]="autoGroupColumnDef"
      (rowSelected)="onRowSelected($event)">
  </ag-grid-angular>
</div>