<ix-content-header
    variant="Secondary"
    headerTitle="Select Devices"
    headerSubtitle="Select the devices to deploy to">
</ix-content-header>

<div class="radio-group">
    <label>Deploy to Devices</label>
</div>

<app-group-list [deviceType]="deviceType" 
[fileTypeValue]="fileTypeValue"
[updateCommand]="updateCommand"
    (selectedGroups)="selectionHandler($event)">
></app-group-list>