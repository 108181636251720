<ix-content-header
    variant="Secondary"
    headerTitle="File Type"
    headerSubtitle="Select the file to fetch and edit">
</ix-content-header>

<ix-select (valueChange)="typeChanged($event)" mode="single" [value]="value">
    <ix-select-item 
        *ngFor="let type of fileTypes"
        [label]="type" 
        [value]="type"
        (itemClick)="onItemClick($event)">
    </ix-select-item>
</ix-select>

<div *ngIf="fileType.length > 0">
    <div *ngIf="!isLoading; else spin">
        <div *ngIf="!isError">
            <app-generic-file-editor
                [file]="file">
            </app-generic-file-editor>
        </div>
    </div>
</div>

<ng-template #spin>
    <div class="spinner">
        <p>Loading details ...</p>
    </div>
    <div class="spinner">
        <ix-spinner size="large"></ix-spinner>
    </div>
</ng-template>
<ng-template #customModal let-modal>
  <ix-modal>
    <ix-modal-header> Exit without saving? </ix-modal-header>
    <ix-modal-content>The modified values will be lost. Do you still want to proceed?</ix-modal-content>
    <ix-modal-footer class="footer">
      <ix-button outline class="dismiss-modal" (click)="modal.dismiss('dismiss')">
        Cancel
      </ix-button>
      <ix-button class="close-modal" (click)="modal.close('okay')">
        OK
      </ix-button>
    </ix-modal-footer>
  </ix-modal>
</ng-template>
<ng-template #customModalForErrorMessage>
  <ix-modal>
      <ix-modal-header> {{ errorMessage }} </ix-modal-header>
  </ix-modal>
</ng-template>