import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { RowSelectedEvent } from 'ag-grid-community';
import { ColDef, GetDataPath, GetRowIdFunc, GetRowIdParams, GridOptions } from 'ag-grid-enterprise';

@Component({
  selector: 'app-fleet-group-selector-modal',
  templateUrl: './fleet-group-selector-modal.component.html',
  styleUrls: ['./fleet-group-selector-modal.component.css']
})
export class FleetGroupSelectorModalComponent{

  @Input() filePath: any[] = []
  @Output() selection = new EventEmitter<string | undefined>();

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  public columnDefs: ColDef[] = [
    { },
  ]
  public gridOptions: GridOptions = {
    animateRows: true,
    treeData: true,
    groupDefaultExpanded: 0
  };
  public getDataPath: GetDataPath = (data: any) => {
    return data.filePath;
  };
  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  };
  public autoGroupColumnDef: ColDef = {
    headerName: 'Device Fleet',
    minWidth:500,
    width:500,
    cellRendererParams: {
      suppressCount: true,
      checkbox: true,
      innerRenderer: FileCellRenderer,
    },
    sortable: false, filter: false,
    cellClass: 'no-right-border',
    pinned:'left',
  };
  constructor() {}
  onRowSelected(e: RowSelectedEvent): void {
    const selectedRow = this.agGrid.api.getSelectedRows()
    if(e.node.isSelected() && selectedRow.length > 0) {
      this.selection.emit(e.data)
    }else if(selectedRow.length == 0){
      this.selection.emit()
    }
  }  
}

export class FileCellRenderer {
  private eGui: any;

  init(params: any) {
    const tempDiv = document.createElement('div');
    const groupName = params.value.indexOf(':')
    const value = groupName > 0 ? params.value.substring(groupName + 1) : params.value;
    tempDiv.innerHTML = value;

    this.eGui = tempDiv.firstChild;
  }
  getGui() {
    return this.eGui;
  }
}
